import React from "react";

interface Props {
  color: string;
}

const BackgroundColor: React.FC<Props> = ({ color, children }) => {
  return (
    <div style={{ height: "100%", backgroundColor: color }}>{children}</div>
  );
};

export default BackgroundColor;
