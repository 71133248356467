import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import BackgroundColor from "./BackgroundColor";
import BackgroundGradient from "./BackgroundGradient";
import zIndexLayers from "./zIndexLayers";

interface Props {
  src: string;
}

const BackgroundWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const BackgroundDiv = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  z-index: ${zIndexLayers.backgroundImageLayer};
`;

const BackgroundImage: React.FC<Props> = ({ src, children }) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    divRef.current.style.backgroundImage = `url(${src})`;
  }, [src]);

  return (
    <BackgroundWrapper>
      <BackgroundDiv ref={divRef}>
        <BackgroundColor color="rgba(0,0,0,.3)">
          <BackgroundGradient />
        </BackgroundColor>
      </BackgroundDiv>
      {children}
    </BackgroundWrapper>
  );
};

export default BackgroundImage;
