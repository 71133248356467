type Instructeur = {
  name: string;
  description: string;
  photo?: string;
  photoNote?: string;
};

export const instructeurs: Instructeur[] = [
  {
    name: "Amar",
    description:
      "Amar staat je met raad en daad bij op je weg naar het rijbewijs! Van begin tot eind begeleidt hij je niet alleen naar je bewijs, maar ook naar een goede bestuurder op de weg voor de rest van je leven.",
    photo: "amar.jpg",
    photoNote: "Amar op de foto rechts",
  },
  {
    name: "Rima",
    description:
      "Rima is onze deskundige vrouw. Altijd bereid tot het uiterste te gaan om je rijbewijs te halen, het maakt niet uit hoe lang het duurt. Iedereen leert op zijn eigen tijd. Rima is geduldig en goed in communicatie.",
    photo: "rima.jpg",
    photoNote: "Rima op de foto links",
  }
];
