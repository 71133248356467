import React from "react";
import BackgroundImage from "../general/BackgroundImage";
import Box from "../general/Box";
import useViewPort from "../../hooks/useViewPort";

interface Props {
  height: string | number;
}

const BannerBackground: React.FC<Props> = ({ height, children }) => {
  const { breakpoint } = useViewPort();

  const srces = {
    xs: "/images/groep-geslaagden-mobile-300.jpg",
    sm: "/images/groep-geslaagden-mobile-400.jpg",
    md: "/images/groep-geslaagden-ipad-600.jpg",
    lg: "/images/groep-geslaagden-desktop-900.jpg",
    xl: "/images/groep-geslaagden-desktop-900.jpg",
  };

  return (
    <Box style={{ height, overflowX: "hidden" }}>
      <BackgroundImage src={srces[breakpoint]}>{children}</BackgroundImage>
    </Box>
  );
};

export default BannerBackground;
