import React from "react";
import styled from "styled-components";

const StyledBackgroundGradient = styled.div`
  background: rgb(0, 38, 161);
  background: linear-gradient(
    0deg,
    rgba(0, 38, 161, 0.7524644549763033) 9%,
    rgba(0, 38, 161, 0.3027014218009479) 100%
  );
`;

interface Props {}

const BackgroundGradient: React.FC<Props> = ({ children }) => {
  return (
    <StyledBackgroundGradient style={{ height: "100%" }}>
      {children}
    </StyledBackgroundGradient>
  );
};

export default BackgroundGradient;
