import React from "react";
import Box from "../general/Box";
import { H2, P } from "../general/Typography";
import { instructeurs } from "./instructeurs";
import Button from "../general/Button";
import { Col, Row } from "reactstrap";
import Flex from "../general/Flex";
import useViewPort from "../../hooks/useViewPort";

interface Props { }

const InstructeursLijst: React.FC<Props> = () => {
  const { breakpoint } = useViewPort();
  return (
    <Row>
      {instructeurs.map((instructeur, i) => (
        <>
          <Col xs="12" lg="6" xl="5">
            <Box text-center mb="5">
              <Row>
                <Col xs="12" md="6">
                  <Flex
                    flex-column
                    justify-content="end"
                    style={{ height: "100%" }}
                    text-md-left
                  >
                    <H2 display>{instructeur.name}</H2>
                    <P light>{instructeur.description}</P>
                    <Box mt="2" mb="4">
                      <Button
                        to={`/contact?message=Hoi ${instructeur.name},<br><br>`}
                      >
                        Neem contact op
                      </Button>
                    </Box>
                  </Flex>
                </Col>
                <Col xs="12" md="6">
                  {instructeur.photo && (
                    <Box px="5" px-lg="0">
                      <div
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <img
                          src={`/images/${instructeur.photo}`}
                          style={{
                            width: "100%",
                            maxWidth: 300,
                            maxHeight: ["xs", "sm"].includes(breakpoint)
                              ? 1000
                              : 280,
                            objectFit: "cover",
                          }}
                          alt={instructeur.photoNote}
                        />
                        <P
                          style={{
                            position: "absolute",
                            bottom: -45,
                            width: "100%",
                          }}
                          light
                          cursive
                          className="mt-2"
                        >
                          {instructeur.photoNote}
                        </P>
                      </div>
                    </Box>
                  )}
                </Col>
              </Row>
            </Box>
          </Col>
          {i % 2 === 0 && <Col className="d-none d-xl-block" xs="0" xl="2"></Col>}
        </>
      ))}
    </Row>
  );
};

export default InstructeursLijst;
